import React from "react";
import PropTypes from "prop-types";
import FluidImage from "~components/fluidImage";
import { Link } from "gatsby";

function GalleryBlock({ blok }) {
  return (
    <li className="relative" key={`gallery-${blok.uid}`}>
      <div className="block group overflow-hidden w-full aspect-w-10 aspect-h-7">
        <div className="object-cover w-full h-full p-2 lg:p-4 gallery-item relative">
          <div className="transform flex items-end content-center transition -translate-x-full bg-black group-hover:translate-x-0 opacity-50 w-full h-full absolute inset-0 z-10">
            <h4
              className="pl-4 pb-4 lg:pl-10 lg:pb-10 text-2xl text-left"
              style={{ maxWidth: "80%" }}
            >
              {blok.title}
            </h4>
          </div>
          <FluidImage image={blok.image} blok={blok} />
        </div>
        <button type="button" className="absolute inset-0">
          <span className="sr-only">View details for IMG</span>
        </button>
      </div>
    </li>
  );
}

GalleryBlock.propTypes = {
  blok: PropTypes.object,
};

function galleryItem({ blok }) {
  if (blok.link != "") {
    return (
      <Link to={blok.link}>
        <GalleryBlock blok={blok} />
      </Link>
    );
  } else {
    return <GalleryBlock blok={blok} />;
  }
}

export default galleryItem;

galleryItem.propTypes = {
  blok: PropTypes.object,
};
