import SbEditable from "storyblok-react";
import React from "react";
import PropTypes from "prop-types";

import ImageWithBorder from "~components/imageWithBorder";
import ServiceItem from "~components/serviceItem";
import GalleryItem from "~components/galleryItem";
import ImageWithTextRow from "~components/imageWithTextRow";
import Partner from "~components/partner";
import FormInput from "~components/formInput";
import ListSelect from "~components/listSelect";
import ContactRow from "~components/contactRow";
import CountStat from "~components/countStat";

import Hero from "~slices/hero";
import ImageWithText from "~slices/imageWithText";
import Services from "~slices/services";
import ImageWithTextOverlap from "~slices/imageWithTextOverlap";
import ImageWithCopy from "~slices/imageWithCopy";
import Gallery from "~slices/gallery";

import Partners from "~slices/partners";
import SmallTextSection from "~slices/smallTextSection";
import InfoBlocks from "~slices/infoBlocks";
import PageTitle from "~slices/pageTitle";
import GlyphsLarge from "~slices/glyphsLarge";
import text from "~slices/text";

import DetailsSection from "~slices/detailsSection";

import ContactForm from "~slices/contactForm";

import BikeStats from "~slices/bikeStats";
import Instagram from "~slices/instagram";
import PartnerSlider from "~slices/partnerSlider";
import SplitView from "~slices/splitView";

const Components = {
  hero: Hero,
  image_with_text: ImageWithText,
  homepage_services: Services,
  image_with_text_overlap: ImageWithTextOverlap,
  gallery: Gallery,
  banner_with_text: ImageWithCopy,
  image_with_border: ImageWithBorder,
  service_block_homepage: ServiceItem,
  gallery_item: GalleryItem,
  small_text_block: SmallTextSection,
  our_partners: Partners,
  info_blocks: InfoBlocks,
  page_title: PageTitle,
  image_with_text_row: ImageWithTextRow,
  partner: Partner,
  services_large: GlyphsLarge,
  contact_form: ContactForm,
  form_input: FormInput,
  form_select: ListSelect,
  contact_details: DetailsSection,
  contact_row: ContactRow,
  stats: BikeStats,
  countup_stat: CountStat,
  instagram: Instagram,
  partners_slider: PartnerSlider,
  split_view: SplitView,
  text_block: text
};

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return (
      <SbEditable content={blok}>
        <Component blok={blok} />
      </SbEditable>
    );
  }

  var keys = Object.keys(blok);

  return (
    <div className="text-2xl text-white bg-black text-center">
      <div>
        <marquee>
          {" "}
          <span className="text-red-600 font-bold pb-16">
            !!! ERROR !!! 错误 !!! خطأ !!! ERROR !!! 错误 !!! خطأ !!! ERROR !!!
            错误 !!! خطأ !!! ERROR !!! 错误 !!! خطأ !!! ERROR !!! 错误 !!! خطأ{" "}
          </span>
        </marquee>
      </div>

      <div>
        The component{" "}
        <strong>
          {blok.component} - {blok.uid}{" "}
        </strong>{" "}
        has not been created yet.
      </div>

      {keys.map((item) => {
        return <p key={item._uid}>key={item}</p>;
      })}

      <div>
        <marquee>
          {" "}
          <span className="text-red-600 font-bold pb-16">
            !!! ERROR !!! 错误 !!! خطأ !!! ERROR !!! 错误 !!! خطأ !!! ERROR !!!
            错误 !!! خطأ !!! ERROR !!! 错误 !!! خطأ !!! ERROR !!! 错误 !!! خطأ{" "}
          </span>
        </marquee>
      </div>
    </div>
  );
};

export default DynamicComponent;

DynamicComponent.propTypes = {
  blok: PropTypes.object,
};
