import React from "react";
import Button from "~components/button";
import PropTypes from "prop-types";
import RichText from "~components/richText";
import DynamicComponent from "~components/dynamicComponent";

export default function Services({ blok }) {

  var slugify = require('slugify')
  var slug = slugify(blok.subline || blok.uid, {lower: true});


  return (
    <section id={slug}>
      <div className='bg-white'>
      <div className="max-w-7xl mx-auto py-8 lg:py-16 px-4 sm:px-6 lg:px-8 ">
        <div className="text-center">
          <h2 className="text-base font-regular text-theme tracking-wide text-3xl lg:text-3xl">
            {blok.subline}
          </h2>
          <h3 className="mt-1 text-4xl font-bold text-black sm:text-5xl sm:tracking-tight lg:text-6xl">
            {blok.headline}
          </h3>
          <div className="max-w-2xl mt-5 mx-auto text-xl text-black font-light">
            <RichText document={blok.copy} />
          </div>

          <div className="flex flex-wrap justify-center">
            {blok.block.map((item) => {
              return <DynamicComponent blok={item} key={item._uid} />;
            })}
          </div>
          <div>
            {blok.cta_text && (
              <Button text={blok.cta_text} href={blok.cta_link} arrow />
            )}
          </div>
        </div>
      </div>
      </div>
    </section>
  );
}

Services.propTypes = {
  blok: PropTypes.object,
};
