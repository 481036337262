import React from "react";
import PropTypes from "prop-types";

function Arrow(){
  return(
  <div className='absolute left-full ml-12 hidden lg:block'>
  <svg xmlns="http://www.w3.org/2000/svg" width="48.564" height="47.334" viewBox="0 0 48.564 47.334">
    <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M20.648,5.818l2.406-2.406a2.591,2.591,0,0,1,3.674,0L47.8,24.472a2.591,2.591,0,0,1,0,3.674L26.729,49.217a2.591,2.591,0,0,1-3.674,0l-2.406-2.406a2.6,2.6,0,0,1,.043-3.718L33.753,30.65H2.6a2.6,2.6,0,0,1-2.6-2.6V24.58a2.6,2.6,0,0,1,2.6-2.6H33.753L20.692,9.535A2.586,2.586,0,0,1,20.648,5.818Z" transform="translate(0 -2.647)"/>
  </svg>
  </div>
  )
}


function ServiceItem({ blok }) {
  var large = blok.large;


  return (
    <div
      className={`p-8 ${
        large ? "w-full md:w-1/1 lg:w-1/3" : "md:w-1/1 lg:w-1/5"
      } flex flex-col text-center items-center`}
      key={`glyphCard${blok.uid}`}
    >
      <div
        className={`${
          large ? "w-40 h-40 lg:w-60 lg:h-60" : "w-36 h-36 lg:w-40 lg:h-40"
        } inline-flex items-center justify-center mb-5 flex-shrink-0 opacity-100 relative`}
      >
        <img src={blok.image.filename} className='service-item'></img>
        {blok.arrow 
        &&        <Arrow/>
        }
      </div>
      <div className="flex-grow">
        <h2 className="text-black text-3xl title-font font-light mb-3">
          {blok.headline}
        </h2>
        <p className="text-black text-xl title-font font-light mt-3">
          {blok.subline}
        </p>

      </div>
    </div>
  );
}

export default ServiceItem;

ServiceItem.propTypes = {
  blok: PropTypes.object,
};
