import React from "react";
import RichText from "~components/richText";
import PropTypes from "prop-types";

export default function Instagram({ blok }) {

  var slugify = require('slugify')
  var slug = slugify(blok.headline || blok.uid, {lower: true});

  return (
    <section id={slug}>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-8 px-4 lg:py-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-xl lg:text-3xl font-regular text-black tracking-wide ">
              {blok.headline}
            </h2>
            <p className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              {blok.subline}
            </p>
            <div className="max-w-4xl mt-5 mx-auto text-xl text-black font-light">
              <RichText document={blok.copy} />
            </div>
          </div>
          <div className="mt-8 lg:mt-16">
            <covet-pics-widget
              gallery-embed-id={blok.embed_id}
            ></covet-pics-widget>
          </div>
        </div>
      </div>
    </section>
  );
}

Instagram.propTypes = {
  blok: PropTypes.object,
};
