import React from "react";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

export default function ContactForm({ blok }) {
  return (
    <section className="bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 pt-4">
        <form
          action={blok.redirect}
          method="POST"
          data-netlify="true"
          name={blok.form_name}
          netlify-honeypot="bot-field"

        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value={blok.form_name} />

          <div className="grid grid-cols-6 gap-2">
            {blok.block.map((item) => {
              return <DynamicComponent blok={item} key={item._uid} />;
            })}
          </div>

          <div className="py-3 text-left">
            <button
              type="submit"
              className="inline-flex items-center justify-center px-6 py-2 border-transparent text-lg text-white font-light bg-theme rounded-md hover:bg-theme-hover"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

ContactForm.propTypes = {
  blok: PropTypes.object,
};
