import React from "react";
import PropTypes from "prop-types";
import RichText from "~components/richText";
import DynamicComponent from "~components/dynamicComponent";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";

/*
key=_uid

key=copy

key=block

key=email

key=headline

key=component

key=phone_number

key=_editable
*/

export default function SplitView({ blok }) {
  return (
    <section>
      <div className="lg:grid lg:grid-cols-5 z-50">
        <div className="bg-theme w-full h-full p-4 lg:p-10 col-span-2">
          <h2 className="font-medium text-xl text-white">{blok.headline}</h2>

          <div className="font-regular text-lg text-white">
            <RichText document={blok.copy} />
          </div>
          {blok.email &&
            <p className="text-xl text-white"><MailIcon className='w-8 h-8 inline mr-2' /><a href={`mailto:${blok.email}`}>{blok.email}</a></p>
          }
          {blok.phone_number &&
            <p className="text-xl text-white"><PhoneIcon className='w-8 h-8 inline mr-2' /> <a href={`tel:${blok.phone_number}`}>{blok.phone_number}</a></p>
          }


        </div>

        <div className="col-span-3">
        <h2 className="font-medium text-xl text-white pt-4 pl-4 lg:pt-8 lg:pl-8">Send us a message</h2>

          {blok.block.map((item) => {
            return <DynamicComponent blok={item} key={item._uid} />;
          })}
        </div>
      </div>
    </section>
  );
}

SplitView.propTypes = {
  blok: PropTypes.object,
};
