/* eslint-disable */

import React from "react";
import RichText from "~components/richText";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

import SwiperCore, { Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination, Autoplay]);

export default function PartnerSlider({ blok }) {

  var slugify = require('slugify')
  var slug = slugify(blok.headline || blok.uid, { lower: true });

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  pagination.displayName = "Pagenator";

  return (
    <section id={slug}>
      <div className="bg-gray-200">
        <div className="max-w-7xl mx-auto py-8 px-4 lg:py-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-xl lg:text-3xl font-regular text-black tracking-wide ">
              {blok.subline}
            </h2>
            <p className="mt-4 text-4xl font-bold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl mb-0">
              {blok.headline}
            </p>
            <div className="max-w-4xl mt-4 mx-auto text-xl text-black font-light">
              <RichText document={blok.copy} />
            </div>
          </div>
          <div className="mt-8 lg:mt-16 lg:mb-16">
            <Swiper
              // install Swiper modules
              modules={[Pagination]}
              slidesPerView={2}
              pagination={pagination}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
            >
              {blok.block.map((item) => {
                return <SwiperSlide className='pb-16 md:pb-32'>
                  <DynamicComponent blok={item} key={item._uid} />              </SwiperSlide>
                  ;
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

PartnerSlider.propTypes = {
  blok: PropTypes.object,
};
