import React from "react";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

export default function InfoBlocks({ blok }) {
  return (
    <>
      <section className="bg-black">
        <div className="max-w-7xl mx-auto pt-16 pb-32 lg:pb-64 px-4 sm:px-6 lg:px-8 ">
          <div className="text-center">
            <h3 className="text-2xl font-regular text-theme-grey tracking-wide sm:text-4xl">
              {blok.subline}
            </h3>
            <h2 className="mt-5 text-4xl font-bold text-white sm:text-5xl sm:tracking-tight lg:text-6xl uppercase pb-0">
              {blok.headline}
            </h2>

            <div>
              {blok.block.map((item) => {
                return <DynamicComponent blok={item} key={item._uid} />;
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

InfoBlocks.propTypes = {
  blok: PropTypes.object,
};
