import React from "react";
import PropTypes from "prop-types";
import RichText from "~components/richText";
import FluidImage from "~components/fluidImage";
import DynamicComponent from "~components/dynamicComponent";

export default function BikeStats({ blok }) {
  var slugify = require('slugify')
  var slug = slugify(blok.headline || blok.uid, {lower: true});

  return (
    <>
      <section id={slug}>
        <div className="bg-white text-black">
          <div className="mx-auto py-8 lg:pt-16 lg:pb-64 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-lg font-semibold text-black tracking-wide ">
                {blok.subline}
              </h2>
              <p className="mb-0 mt-2 text-4xl font-bold text-theme sm:text-5xl sm:tracking-tight lg:text-6xl">
                {blok.headline}
              </p>
            </div>

            <div className="py-4 lg:mt-16">
              <div className="w-full">
                <FluidImage blok={blok} image={blok.image} />
              </div>
            </div>
            <div className="lg:-mt-24 relative">
              <div className="max-w-7xl mx-auto bg-white z-5 px-4 lg:px-16">
                <p className="lg:pt-8  lg:pb-4 text-lg lg:text-2xl font-semibold text-black sm:tracking-tight text-center">
                  {blok.stats_subline}
                </p>

                <dl className="py-4 lg:py-8 text-center grid grid-cols-3 gap-8">
                  {blok.block.map((item) => {
                    return <DynamicComponent blok={item} key={item._uid} />;
                  })}
                </dl>

                <div className="lg:py-8">
                  <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16">
                    <div>
                      <div className="text-lg space-y-6">
                        <RichText document={blok.left_col} />
                      </div>
                    </div>

                    <div>
                      <div className="text-lg space-y-4 second-col">
                        <RichText document={blok.right_col} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

BikeStats.propTypes = {
  blok: PropTypes.object,
};
