import React from "react";
import Button from "../components/button";
import StackedImages from "../components/stackedImages";
import PropTypes from "prop-types";
import RichText from "~components/richText";

export default function ImageWithText({ blok }) {
  return (
    <section id="about" className='overflow-hidden'>
      <div className="grid grid-cols-1 lg:grid-cols-7 gap-4 text-white max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-10 lg:py-16 xl:px-0 xl:pt-32 xl:pb-56">
        <div
          className={`flex items-center ${
            blok.reverse ? "order-0" : "order-2"
          } col-span-3`}
        >
          <div className="lg:pt-6">
            <h2
              className="mt-2 text-4xl font-bold sm:text-5xl sm:tracking-tight lg:text-6xl"
              dangerouslySetInnerHTML={{ __html: blok.headline }}
            ></h2>
            <div className="mt-4">
              <div className="text-base lg:max-w-none mb-8">
                <div className="text-lg">
                  <RichText document={blok.copy} />
                </div>
              </div>
              {blok.cta_text && (
                <Button href={blok.cta_link} text={blok.cta_text} />
              )}
            </div>
          </div>
        </div>
        <div></div>
        <div className="flex justify-center items-center column hidden lg:block col-span-3">
          <StackedImages reverse={blok.reverse} />
        </div>
      </div>
    </section>
  );
}

ImageWithText.propTypes = {
  blok: PropTypes.object,
};
