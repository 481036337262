import React from "react";
import Button from "../components/button";
import ButtonTrans from "../components/buttonTrans";
import PropTypes from "prop-types";
import FixedImage from "~components/fixedImage";

export default function hero({ blok }) {
  return (
    <section
      id="hero"
    >
      <div className="relative h-screen overflow-hidden hero"       style={{ maxHeight: "870px" }}>
      <div className="relative h-screen overflow-hidden hero absolute inset-x-0 bottom-0" />
      <div>
        <div className="sm:overflow-hidden h-100">
          <div className="absolute inset-0">
            <div className="h-full w-full object-cover">
              <FixedImage blok={blok} image={blok.image} width={1920} critical={true} blur={false}/>
            </div>

            <div className="absolute inset-0 bg-gradient-to-r from-gray-500 to-gray-300 mix-blend-multiply" />
          </div>
          <div className="px-4 sm:px-6 lg:px-8 absolute bottom-24 lg:bottom-14">
            <h1
              className="text-4xl font-bold tracking-tight lg:text-8xl text-white"
              dangerouslySetInnerHTML={{ __html: blok.headline }}
            ></h1>
            <p
              className="mt-2 lg:mt-6 max-w-lg text-2xl lg:text-4xl text-white sm:max-w-3xl"
              dangerouslySetInnerHTML={{ __html: blok.subline }}
            ></p>
            <div className="mt-2 lg:mt-10 max-w-sm sm:max-w-none">
              <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                <Button rightLine={true} href={blok.cta_1_link} text={blok.cta_1_text}/>
                <ButtonTrans href={blok.cta_2_link} text={blok.cta_2_text} />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  );
}

hero.propTypes = {
  blok: PropTypes.object,
};
