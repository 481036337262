import React from "react";
import PropTypes from "prop-types";

function ButtonTrans({ text = "Join Now", href = "#" }) {
  return (
    <a
      href={href}
      className={`inline-flex justify-center items-center px-6 py-2 border-transparent text-lg text-white font-light bg-transparent hover:bg-transparent`}
    >
      {text}
    </a>
  );
}

ButtonTrans.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
};

export default ButtonTrans;
