import React from "react";
import PropTypes from "prop-types";
import RichText from "~components/richText";

export default function Text({ blok }) {
    return (
        <>
            <section className="bg-black">
                <div className="max-w-7xl mx-auto px-4 py-8 lg:py-16">
                    <p
                        className={`font-regular text-xl py-4 lg:py-2 text-white`}
                    >
                        <RichText document={blok.copy} />
                    </p>        
                </div>
            </section>
        </>
    );
}

Text.propTypes = {
    blok: PropTypes.object,
};
