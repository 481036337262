import React, { useState } from "react";
import revmoto_logo from "../images/sample/logo_white.png";

const navigation = {
  solutions: [
    { name: "Marketing", href: "#" },
    { name: "Analytics", href: "#" },
    { name: "Commerce", href: "#" },
  ],
  support: [
    { name: "About Us", href: "/#about" },
    { name: "How It Works", href: "/#how-it-works" },
    { name: "Terms & Conditions", href: " /terms-and-conditions" },
    { name: "Privacy Policy", href: "/privacy-policy" },

  ],
  company: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/revmoto.australia/",
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/revmoto.australia/",
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/revmotoaus",
      // eslint-disable-next-line react/display-name
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

export default function Footer() {

  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);


  const Klaviyo = require('node-klaviyo');

  const KlaviyoClient = new Klaviyo({
    publicToken: 'Vrv2sQ',
  });

  const handleSubmit = async e => {
    e.preventDefault();

    KlaviyoClient.public.identify({
      email: name,
      post: true, //defaults to false
    });

    setSubmitted(true);
  }

  const handleChange = event => {
    setName(event.target.value);
  };

  return (
    <footer className="bg-black" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:pt-8 lg:pb-0 lg:px-2">
        <div className="grid-cols-1 xl:grid xl:grid-cols-3 xl:gap-8 lg:pb-8">
          <div className="grid grid-cols-1 gap-8 xl:col-span-2">
            <div className="grid-cols-1 grid md:grid-cols-3 md:gap-8">
              <div className="mb-4 lg:my-4 lg:my-4">
                <img src={revmoto_logo} className="w-6/12" />

                <ul
                  role="list"
                  className="space-y-4 h-full relative hidden lg:block"
                >
                  <li className="">
                    <br />
                  </li>

                  <li className="">
                    <br />
                  </li>


                </ul>
              </div>

              <div className="my-4 lg:my-4">
                <ul role="list" className="space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-white hover:text-theme"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="my-4 lg:my-4">
                <ul role="list" className="space-y-4">
                  {navigation.social.map((item) => (
                    <li key={item.name}>
                      <a
                        key={item.name}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer" 
                        className="text-base text-white hover:text-theme"
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon
                          className="h-6 w-6 inline mr-2"
                          aria-hidden="true"
                        />{" "}
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="my-38 lg:my-4 relative">
            <div className="lg:absolute bottom-0 w-full">
              <h3 className="text-base text-white capitalize mb-4">
                Subscribe to our newsletter
              </h3>
              <form onSubmit={handleSubmit} className='lg:flex'>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  onChange={handleChange}
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="appearance-none min-w-0 w-full bg-black border-1 border-white py-2 px-4 text-base text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                  placeholder="Enter your email"
                />
                <div className="rounded-md mt-4 lg:mt-0 flex-shrink-0">
                  <button
                    type="submit"
                    title='Submit'
                    onClick={() => setSubmitted(true)}
                    className="w-full h-full bg-white border border-transparent py-2 px-4 flex items-center justify-center text-base font-medium text-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                    OK
                  </button>
                </div>
              </form>

              {submitted &&
                <h3 className="text-base text-theme capitalize mt-4">
                  Thankyou for your submission.
                </h3>
              }

            </div>
          </div>
        </div>
        <div className='pt-4 lg:pt-0 lg:border-t border-white'>
          <p className="text-white font-extralight">
            2021 © RevMoto Australia - Powered By{" "}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://tensq.com.au"
              target="_blank"
              alt="Ten Squared"
              title="Ten Squared"
            >
              Ten Squared
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
