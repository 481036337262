import { Link, useStaticQuery, graphql } from "gatsby";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition, Popover } from "@headlessui/react";
import DynamicComponent from "~components/dynamicComponent";

import Button from "../components/button";
import revmoto_logo from "../images/sample/logo_white.png";
import revmoto_logo_black from "../images/sample/logo_black.png";
import useStoryblok from "../lib/storyblok";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

function Header() {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  function openModal(event) {
    event.preventDefault();

    setOpen(true);
  }

  var navigation = [];

  const header_data = useStaticQuery(graphql`
    {
      storyblokEntry(full_slug: { eq: "header" }) {
        content
        name
      }
    }
  `);

  let story = header_data.storyblokEntry;

  story = useStoryblok(story);

  for (var block_item in story.content.block) {
    navigation.push({
      name: story.content.block[block_item]["headline"],
      href: story.content.block[block_item]["link"],
      modal: story.content.block[block_item]["trigger_modal"],
    });
  }

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  useEffect(() => {
    var elements = window.document.getElementsByClassName("open-modal");

    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener(
        "click",
        function (event) {
          openModal(event);
        },
        false
      );
    }
  }, []); // run the effect only once to avoid multiple event attachment

  return (
    <>
      <section className='hidden'>
        {story.content.modal_content.map((item) => {
          return <DynamicComponent blok={item} key={item._uid} />;
        })}

      </section>
      <Transition.Root show={open} as={Fragment} id="headlessui-portal-root">
        <Dialog
          as="div"
          className="fixed z-60 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
          disableScrollLock 
        >

          <div className="flex items-end justify-center min-h-screen p-4 pb-4 lg:pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >

              <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full mt-4 lg:mt-0 relative">
                <button className="rounded-md m-4 inline-flex items-center justify-center text-gray-700 focus:outline-none focus:ring-0 focus:ring-inset absolute top-0 right-0" onClick={() => setOpen(false)}>
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {story.content.modal_content.map((item) => {
                  return <DynamicComponent blok={item} key={item._uid} />;
                })}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Popover
        as="header"
        className="absolute lg:fixed top-0 left-0 z-50 w-full"
      >
        <div
          className={`py-6 transition-colors duration-500 ${showScrolling ? "scrolling" : ""
            } ${showReveal ? "bg-transparent lg:bg-black " : "bg-transparent"}`}
        >
          <nav
            className="relative w-full mx-auto flex items-center justify-between px-4 lg:px-8"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full lg:w-auto">
                <Link to="/">
                  <span className="sr-only">RevMoto Australia</span>
                  <img
                    className={`h-8 w-auto  ${showReveal ? "sm:h-10" : "sm:h-14"
                      }`}
                    src={revmoto_logo}
                    alt=""
                  />
                </Link>
                <div className="-mr-2 flex items-center lg:hidden">
                  <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-white focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 lg:flex lg:ml-auto lg:mr-10">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`text-base font-light text-white hover:text-gray-300 ${item.modal ? "open-modal" : ""
                      }`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              {story.content.cta_text && (
                <Button
                  href={story.content.cta_link}
                  text={story.content.cta_text}
                />
              )}
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top lg:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={revmoto_logo_black} alt="" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">

                  {navigation.map((item) => {
                    if (item.modal) {
                      return (
                        <button
                          key={item.name}
                          className={`block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 ${item.modal ? "open-modal" : ""
                            }`}
                          onClick={() => setOpen(true)}
                        >
                          {item.name}
                        </button>

                      )
                    } else {
                      return (<Link
                        key={item.name}
                        href={item.href}
                        className={`block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 `}
                      >
                        {item.name}
                      </Link>
                      )
                    }
                  }
                  )}

                </div>
                <div className="mt-6 px-5">
                  {story.content.cta_text && (
                    <Button
                      href={story.content.cta_link}
                      text={story.content.cta_text}
                    />
                  )}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}

export default Header;
