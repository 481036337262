import PropTypes from "prop-types";
import React, { useEffect } from "react";

import Header from "./header";
import Footer from "./footer";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import "@covet-pics/covet-pics-widget/dist/index.js"; // You can also use <link> for styles

function Layout({ children }) {
  useEffect(() => {
    AOS.init();
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]')
    }
    
  }, []); // it's important to run the effect only once to avoid multiple event attachment

  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-black">
      <Header />

      <main className="flex-1 w-full">{children}</main>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
