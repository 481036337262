import React from "react";
import PropTypes from "prop-types";
import Ticker from "../components/ticker";

function CountStat({ blok }) {
  return (
    <div className="flex flex-col">
      <dt className="order-2 mt-2 text-lg leading-6 font-light text-black">
        {blok.headline}
      </dt>
      <Ticker end={blok.to} />
    </div>
  );
}

CountStat.propTypes = {
  blok: PropTypes.object,
};

export default CountStat;
