import React from "react";
import PropTypes from "prop-types";

function Button({ text = "Join Now", href = "#" }) {
  return (
    <a
      href={href}
      className={`inline-flex items-center justify-center px-6 py-2 border-transparent text-lg text-white font-light bg-theme rounded-md hover:bg-theme-hover`}
    >
      {text}
    </a>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
};

export default Button;
