import React from "react";
import Button from "~components/button";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

export default function Gallery({ blok }) {
  var slugify = require('slugify')
  var slug = slugify(blok.headline || blok.uid, {lower: true});

  return (
    <section id={slug}>
      <div className="max-w-7xl bg-black mx-auto px-2 sm:px-6 lg:px-8 py-16 lg:py-24">
        <div className="text-center">
          <h2 className="text-base font-regular text-theme-grey tracking-wide text-3xl sm:text-4xl">
            {blok.subline}
          </h2>
          <h3 className="mt-5 text-4xl font-bold text-white sm:text-5xl sm:tracking-tight lg:text-6xl max-w-3xl inline-block leading-10	">
            {blok.headline}
          </h3>

          <ul
            role="list"
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 py-8"
          >
            {blok.block.map((item) => {
              return <DynamicComponent blok={item} key={item._uid} />;
            })}
          </ul>

          <div className="pb-6">
            {blok.cta_text && (
              <Button text={blok.cta_text} href={blok.cta_link} arrow />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

Gallery.propTypes = {
  blok: PropTypes.object,
};
