import React from "react";
import PropTypes from "prop-types";

export default function FormInput({ blok }) {
  if (blok.type != "text_area") {
    return (
      <div
        className={`${
          blok.full_width
            ? "col-span-6 sm:col-span-6 mt-1"
            : "col-span-6 sm:col-span-3 "
        }`}
      >
        <input
          type={blok.type}
          placeholder={blok.placeholder}
          name={blok.field_name}
          id={blok.field_name}
          className="mt-1 w-full shadow-sm sm:text-xl font-regular h-12 text-black"
          required={blok.required}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`${
          blok.full_width
            ? "col-span-6 sm:col-span-6 mt-1"
            : "col-span-6 sm:col-span-3 "
        }`}
      >
        <textarea
          type="text"
          placeholder={blok.placeholder}
          name={blok.field_name}
          id={blok.field_name}
          className="mt-1 pt-4 w-full shadow-sm sm:text-xl font-regular h-32 text-black"
          required={blok.required}
        />
      </div>
    );
  }
}

FormInput.propTypes = {
  blok: PropTypes.object,
};
