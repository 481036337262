import React from "react";
import PropTypes from "prop-types";
import FluidImage from "~components/fluidImage";

function ImageWithBorder({ blok }) {
  return (
    <div className="flex justify-center items-center relative w-full h-full mt-4 lg:mt-8 lg:ml-16 lg:-mb-16">
      <div className="flex w-full h-full">
        <div className="transform transition-all ml-4 lg:ml-0 w-full h-full relative">
          <div className="w-full h-full hero">
            <FluidImage image={blok.image} blok={blok} />
          </div>
          <span className="border-theme-grey border-4 lg:border-8 absolute -bottom-4 -left-4 lg:-bottom-8 lg:-left-8 h-full w-full lg:block"></span>
        </div>
      </div>
    </div>
  );
}

export default ImageWithBorder;

ImageWithBorder.propTypes = {
  blok: PropTypes.object,
};
