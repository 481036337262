import React from "react";
import Button from "~components/button";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

export default function GlyphsLarge({ blok }) {

  var slugify = require('slugify')
  var slug = slugify(blok.uid, {lower: true});

  return (
    <section id={slug}>
      <div className="bg-black max-w-screen-2xl mx-auto lg:py-16 px-8 sm:px-6 lg:px-8 ">
        <div className="text-center">
          <div className="flex flex-wrap py-8 justify-center">
            {blok.block.map((item) => {
              return <DynamicComponent blok={item} key={item._uid} />;
            })}
          </div>
          <div className="pb-6">
            {blok.cta_text && (
              <Button text={blok.cta_text} href={blok.cta_link} arrow />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

GlyphsLarge.propTypes = {
  blok: PropTypes.object,
};
