import React from "react";
import PropTypes from "prop-types";
import RichText from "~components/richText";
import FluidImage from "~components/fluidImage";
import Button from "../components/button";

function ImageWithTextOverlap({ blok }) {


  var reverse = blok.reverse;

  var bg = blok.background_color;

  var primaryColor = blok.primary_color || "black";

  var secondaryColor = blok.secondary_color || "theme-grey";

  var fixBottom = blok.unpad_bottom;

  var slugify = require('slugify')
  var slug = slugify(blok.headline || blok.uid, {lower: true});

  return (
    <section id={slug} className='image-with-text-overlap'>
      <div
        className={`px-4 sm:px-6 lg:px-8 bg-${bg} relative ${
          fixBottom ? "py-8 xl:py-16 xl:pt-48" : "py-8 xl:py-48"
        }`}
      >
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative">
            <div className="relative md:p-6">
              <div
                className={`grid grid-cols-1 md:grid-cols-2 md:gap-6 xl:-mt-96 ${
                  fixBottom ? "lg:pb-0" : "xl:pb-32"
                }`}
              >
                <div
                  className={`flex sm:items-center xl:items-end lg:max-w-none p-2 xl:px-6 text-center md:text-left order-2 `}
                >
                  <div>
                    <h2
                      className={`text-3xl font-bold tracking-tight text-${primaryColor} sm:text-5xl pt-4`}
                    >
                      {blok.headline}
                    </h2>

                    <div
                      className={`font-regular text-xl lg:py-6 text-${secondaryColor}`}
                    >
                      <RichText document={blok.copy} />
                    </div>
                    <div>
                      {blok.cta_text && (
                        <Button href={blok.cta_link} text={blok.cta_text} />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`text-gray-500 ${
                    reverse ? "md:order-2" : "md:order-1"
                  } relative`}
                >
                  <div
                    className={`absolute object-cover height-custom-image relative overlap-image ${
                      reverse ? "xl:ml-16" : "xl:mr-16"
                    }`}
                  >
                    <FluidImage blok={blok} image={blok.image} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ImageWithTextOverlap.propTypes = {
  blok: PropTypes.object,
};

export default ImageWithTextOverlap;
