import React from "react";
import PropTypes from "prop-types";
import FixedImage from "~components/fixedImage";

export default function PageTitle({ blok }) {
  var small = blok.small;
  return (
    <section>
      <div
        className={`relative ${
          small ? "py-32 lg:py-64" : "py-64 lg:py-96 xl:py-64"
        }`}
      >
        <div className="absolute inset-x-0 bottom-0" />
        <div>
          <div className="sm:overflow-hidden h-100">
            <div className="absolute inset-0 hero">
              <FixedImage blok={blok} image={blok.image} width={1920} />
              <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-600 mix-blend-multiply opacity-50	" />
            </div>
          </div>
        </div>

        <div
          className={`absolute inset-0 flex w-screen-full ${
            small ? "py-32 lg:py-64" : "py-64 lg:py-96 xl:py-52"
          } items-center justify-center`}
        >
          <h1 className="mt-5 text-4xl font-medium text-white sm:tracking-tight md:text-3xl lg:text-5xl max-w-4xl text-center uppercase">
            {blok.title}
          </h1>
        </div>
      </div>
    </section>
  );
}

PageTitle.propTypes = {
  blok: PropTypes.object,
};
