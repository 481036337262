import React from "react";
import PropTypes from "prop-types";
import FixedImage from "~components/fixedImage";
import { Link } from "gatsby";
import { ArrowRightIcon } from "@heroicons/react/outline";

export default function ImageWithCopy({ blok }) {
  return (
    <section>
      <div className="relative py-44 lg:py-96 overflow-hidden">
        <div className="absolute inset-x-0 bottom-0" />
        <div>
          <div className="sm:overflow-hidden h-full">
            <div className="absolute inset-0">
              <div className="h-full w-full object-cover hero">
                <FixedImage blok={blok} image={blok.image} width={1920} />
              </div>
              <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-600 mix-blend-multiply opacity-50	" />
            </div>
          </div>
        </div>

        <div className="absolute inset-0 flex w-screen-full  py-44 lg:py-96	 items-center justify-center">
          <div className="mt-5 text-3xl lg:text-4xl mx-4 font-bold text-white sm:text-5xl lg:text-6xl max-w-4xl text-center py-96">
            <h2 dangerouslySetInnerHTML={{ __html: blok.copy }}></h2>
            {blok.cta_text && (
              <Link
                to={blok.cta_link}
                className="group inline-flex items-center justify-center border border-transparent text-base font-medium text-2xl text-white"
              >
                {blok.cta_text}
                <ArrowRightIcon
                  className="ml-2 -mr-0.5 h-5 w-5 transition-transform transform group-hover:translate-x-2"
                  aria-hidden="true"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

ImageWithCopy.propTypes = {
  blok: PropTypes.object,
};
