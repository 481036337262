/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";

import square_bike from "../images/panigalev4s02cropped.jpg";

function StackedImages({ reverse }) {
  return (
    <div
      className={`flex justify-center items-center w-full h-full relative ${
        reverse ? "" : ""
      }`}
    >
      <div
        className={`absolute transform transition-all ${
          reverse ? "" : ""
        } w-5/6 xl:ml-32 hidden xl:block`}
        data-aos="zoom-in"
      >
        <img src={square_bike} className="w-full"></img>
        <span className="border-theme border-2 absolute lg:-top-8 lg:-right-10 w-28"></span>
      </div>

      <div
        className="absolute transition-all w-5/6 xl:inset-8	"
        data-aos="zoom-in"
      >
        <img src={square_bike} className="w-full"></img>

        <span className="border-theme border-2 absolute lg:-top-8 lg:-left-10 h-28 hidden xl:block"></span>
        <span className="border-theme border-2 absolute lg:-bottom-8 lg:-right-10 h-28 hidden xl:block"></span>
      </div>
    </div>
  );
}

export default StackedImages;

StackedImages.propTypes = {
  reverse: PropTypes.bool,
};
